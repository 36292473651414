import React from 'react'
// Page Components
import {Article, Header2, Header3, Paragraph} from "../GlobalStyles"
import { SiFacebook, SiInstagram, SiGithub, SiPinterest, SiYoutube, SiTwitter } from "react-icons/si";
import './AboutSection.css';


const AboutSection = () => {
    const amazon = "https://www.amazon.com/Simplified-Sesh-Medew-Netcher-Penmanship/dp/0692974687/ref=sr_1_1?s=books&ie=UTF8&qid=1511480421&sr=1-1&fbclid=IwAR1KC3c7ZRQWkeYITgGszh97H5RmhiZHDy6JhDB0kyaHQXMi40s7ZBAT-x0#customerReviews";
    
    return (
        <Article className="description">
            <div className="title">
                <div className="hide">
                    <Header2>New and Exciting things are coming soon</Header2>
                </div>
            
                <Paragraph>
                Meanwhile please keep in touch via my social sites below.
                </Paragraph>
                <div style={{width: "80%", margin: "auto", display:"flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
                <Paragraph>
                Purchase the book <b>Simplified Sesh Medew Netcher Penmanship: A Lesson In Egyptian Hieroglyphic Writing: Book One: Monoliterals</b>
                </Paragraph>
                <a href={amazon}><img src="/book-cover.jpg" alt="book-cover" style={{width: "100px", height:"auto", margin:"1rem"}}/></a>
                <Header3>
                <a href={amazon}><span  className="green-text">On Amazon</span></a>
                </Header3>
                </div>
                <div style={{textAlign: "center", margin:"1rem 0"}}>
                <a href="https://www.facebook.com/emykhet"><SiFacebook className="social-icons"/></a>
                <a href="https://www.instagram.com/emykhet"><SiInstagram className="social-icons"/></a>
                <a href="https://www.twitter.com/emykhet"><SiTwitter className="social-icons"/></a>
                <a href="https://www.youtube.com/@emykhet"><SiYoutube className="social-icons"/></a>
                <a href="https://www.github.com/emykhet"><SiGithub className="social-icons"/></a>
                <a href="https://www.pinterest.com/emykhet"><SiPinterest className="social-icons"/></a>
                </div>  
            </div>
        </Article>
    )
}

export default AboutSection
