import React from 'react'
// Page Components
import { Paragraph, LogoImage, HeroImage } from "../../GlobalStyles"


const HeaderSection = () => {
    return (
        <HeroImage className="image">
            <LogoImage src="/emykhet-logo-gradient.png" alt=""/>
                <Paragraph>
                    codes | scripts | languages
                </Paragraph>
        </HeroImage>
    )
}


export default HeaderSection