import  styled, {createGlobalStyle, css} from 'styled-components'
const GlobalStyles = createGlobalStyle`
    *{
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: 'DM Mono', monospace;
    }

    button{
        text-transform: uppercase;
        cursor: pointer;
        padding: .8rem 2rem;
        color: #ffffff;
        font-size: ${({fontBig}) => (fontBig ? '1.5rem' : '1rem')};
        margin: ${({margin}) => (margin ? '0 .5rem' : '0')};
        border: none;
        outline: none;
        transition: all 0.5s ease;
        background: ${({primary}) => (primary ? '#956e66' : '#dcc5b6')};
        white-space: nowrap;
        &:hover{
            background-color: #a0a0a0;
        }

        h2{
        font-weight: lighter;
        font-size: 4rem;
        }
        
        h3{
            color: white;
        }
        h4{
            font-weight: bold;
            font-size: 2rem;
        
        }
        a{
            font-size: 1.1rem;
        }
        span{
            font-weight: bold;
            color:#23d997;
        }
        p{
            padding: 3rem 0rem;
            color: #ccc;
            font-size: 1.4rem;
            line-height: 150%;
        }

    }
`

// Reusables
export const Container = styled.div`
    z-index: 1;
    width: 100%;
    max-width: 1300px;
    margin-right: auto;
    margin-left: auto;
    padding-left: 50px;
    padding-right: 50px;

    @media screen and (max-width: 991px){
        padding-left: 30px;
        padding-right: 30px;
    }
`;

export const Button = styled.button`
        text-transform: uppercase;
        cursor: pointer;
        padding: .8rem 2rem;
        color: #ffffff;
        font-size: ${({fontBig}) => (fontBig ? '1.5rem' : '.8rem')};
        margin-top: 1rem;
        margin-bottom: 1rem;
        margin-right: ${({margin}) => (margin ? '.5rem' : '0')};
        border: none;
        outline: none;
        transition: all 0.5s ease;
        white-space: nowrap;
        &:hover{
            background-color: #a0a0a0;
        }

        ${props => props.primary && css`
        background: #8a9188;
        color: white;
        `}
        ${props => props.secondary && css`
        background:#dcc5b6;
        color: white;
        `}
        ${props => props.info && css`
        background: teal;
        color: white;
        `}
        ${props => props.dark && css`
        background: #101522;
        color: white;
        `}
        ${props => props.trans && css`
        background: transparent;
        color:#101522;
        `}
`
export const Row = styled.div`
        color: #333333;
        height: 100vh;
        display:flex;
        flex-flow:row;

        /* Responsive */
        @media screen and (max-width: 1020px){
         flex-flow:column;
        }
`

export const Article = styled.div`
        position: relative;
        width: 100%;
        height: 100vh;
        margin: auto;
        padding: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
         /* Responsive */
         @media screen and (max-width: 1020px){
            padding: 1rem auto;
        }
`
export const HeroImage = styled.div`
display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        position: relative;
        width: 100%;
        background-image: url("/emykhet -quare-logo.jpg");
        /* background-color: #8a9188; */
        background-color: #f08e80;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        z-index: 0;

              /* Responsive */
              @media screen and (max-width: 760px){
           display: none;
    }
    p{
           color: #f0ecec;
           text-transform: uppercase;
           letter-spacing: 1.2px;
        }
`

export const FlexCenter = styled.img`
        display: flex;
        align-items: center;
        justify-content: center;
`

export const LogoImage = styled.img`
        height: 100px;
        width: auto;
`

export const Header = styled.div`
position: relative;
height: 100vh;
display: flex;
align-items: center;
font-family: 'DM Mono', monospace;
color: #ffffff;
`

export const Header1 = styled.h1`
    font-size: 5rem;
    color: #ffffff;
`
export const Header4 = styled.h4`
    font-size: 1.5rem;
    color: #cfcfcf;
`
export const Header2 = styled.h2`
    font-size: 3rem;
    color: #f08e80;
    font-weight: 900;
    font-family: 'Playfair Display';
    text-transform: uppercase;
    text-align: center;

    /* Responsive */
    @media screen and (max-width: 760px){
        font-size: 2.5rem;
    }
`
export const Header3 = styled.h2`
    font-size: 2rem;
    color: #747474;
    font-weight: 900;
    font-family: 'Playfair Display';
    text-transform: uppercase;
    text-align: center;
    a{
        text-decoration: none;
        color: #4b4b4b;
        text-transform: none;
        text-align: center;
    }
`
export const Paragraph = styled.p`
    text-align: justify;
    padding: .5rem 0;
    text-align: center;
`

export default GlobalStyles
