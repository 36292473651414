import React from "react"
import {BrowserRouter as Router} from "react-router-dom"

// Import Global Style
import GlobalStyles from "./GlobalStyles"
// Import Pages
import HomePage from "./pages/HomePage"
// Import Components


function App() {
  return (
    <div className="App">
       <Router>
        <GlobalStyles/>
        <HomePage/>
      </Router>
    </div>
  );
}

export default App;
