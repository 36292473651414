import React from 'react'
// Page Components
import { Row } from "../GlobalStyles"
import HeaderSection from "../components/Header/HeaderSection"
import AboutSection from "../components/AboutSection"


const HomePage = () => {
    return (
        <>
            <Row className="about">   
                <HeaderSection/>
                <AboutSection />    
            </Row>  
        </>
    )
}


export default HomePage

